<template>
  <tr :data-id="discountDetails?._id">
    <td name="discount-name">{{ discountDetails?.discountName }}</td>
    <td>{{ discountDetails?.description }}</td>
    <td>{{ translateDiscountType }}</td>
    <td>{{ discountDetails?.discountValue }}</td>
    <td>{{ discountDetails?.startDate }}</td>
    <td>{{ discountDetails?.endDate }}</td>
    <td>
      <v-app class="transparent-bg">
        <v-switch
          class="toggle-discount-btn"
          :input-value="discountDetails?.isActive"
          @change="toggleDiscountStatus"
        />
      </v-app>
    </td>
    <td>
      <div class="action-btn-container">
        <v-btn
        class="btn-link"
        small
        @click="isLinkedPharmaciesDialogOpen = true">
          <v-icon small style="margin: 0 8px">
            mdi-clipboard-account
          </v-icon>
      </v-btn>
      <v-btn
        class="btn-edit"
        small
        @click="isEditDialogOpen = true">
          <v-icon small>mdi-pencil</v-icon>
      </v-btn>
      <v-btn
        class="btn-delete"
        small
        color="error"
        :disabled="shouldDeleteBeDisabled"
        @click="isDeleteDiscountDialogOpen = true">
        <v-icon small>mdi-trash-can-outline</v-icon>
      </v-btn>
      </div>
    </td>
    <DiscountFormDialog
      v-model="discount"
      dialogTitle="Bearbeiten Rabatt"
      ctaTitle="Rabatt bearbeiten"
      :isDiscountFormDialogOpen.sync="isEditDialogOpen"
      @refresh="$emit('refresh')"
      @submit="editDiscount"
    />
    <LinkedPharmacyDialog
      v-model="discountDetails"
      :isLinkedPharmacyDialogOpen.sync="isLinkedPharmaciesDialogOpen"
      @refresh="$emit('refresh')"
      @submit="assignDiscountToPharmacy"
    />
    <DeleteDiscountDialog
      :isDeleteDiscountDialogOpen.sync="isDeleteDiscountDialogOpen"
      @submit="deleteDiscount"
    />
  </tr>
</template>
<script>
import axios from "axios";
import { mapMutations } from "vuex";
import LinkedPharmacyDialog from "./LinkedPharmacyDialog.vue";
import DeleteDiscountDialog from "./DeleteDiscountDialog.vue";
import DiscountFormDialog from "./DiscountFormDialog.vue";

export default {
  components: {
    LinkedPharmacyDialog,
    DiscountFormDialog,
    DeleteDiscountDialog
  },
  props: {
    discountDetails: Object,
  },
  data() {
    return {
      isLinkedPharmaciesDialogOpen: false,
      isDeleteDiscountDialogOpen: false,
      isEditDialogOpen: false,
      discount: {
        ...this.discountDetails,
        subscribedPharmacies: { ...this.discountDetails.subscribedPharmacies }
      },
    };
  },
  computed: {
    shouldDeleteBeDisabled() {
      return Object.keys(this.discount.subscribedPharmacies || {}).length !== 0 ;
    },
    translateDiscountType() {
      return this.discount?.discountType === "percentage" ? "Prozent" : this.discount?.discountType === "number" ? "Nummer" : "";
    },
  },
  methods:{
    async toggleDiscountStatus(discountStatus){
      try {
        const status = discountStatus ? "active" : "inactive";
        const discountId = this.discount?._id;
        if(discountId){
          const response = await axios.put(`/api/discounts/${discountId}/change_discount_status_to/${status}`);
          if(response.data.length === 2 && response.data[1] > 300){
            const fallbackErrorMessage = "Einige Felder fehlen. Bitte geben Sie den Namen, die Art und den Wert des Rabatts ein, um den Rabatt zu erstellen.";
            this.alertError(response?.data[0]?.error || fallbackErrorMessage);
            return;
          }
          this.alertSuccess(`Der Rabatt wurde ${status === 'active' ? "aktiviert" : "deaktiviert"}`);
          this.$emit('refresh');
        }
      } catch (error) {
        this.alertError();
      }
    },
    isDiscountDataValid(discount){
        return discount._id && discount.discountName && discount.discountType && discount.discountValue;
    },
    async handleSuccessfulResponse() {
      this.$emit('refresh');
      this.isEditDialogOpen = false;
      this.alertSuccess(`Der Rabatt wurde geändert`);
    },
    constructPayload(){
      return {
        discountName: this.discount.discountName,
        description: this.discount.description,
        discountType: this.discount.discountType,
        discountValue: this.discount.discountValue,
        isActive: this.discount.isActive,
        sources: this.discount.sources,
        ...(this.discount.startDate && { startDate: this.discount.startDate }),
        ...(this.discount.endDate && { endDate: this.discount.endDate }),
        ...(this.discount && { _id: this.discount._id }),
      };
    },
    async editDiscount(){
      try{
        const discountPayload = this.constructPayload();
        if(!this.isDiscountDataValid(discountPayload)){
          return;
        }
        const { _id, ...rest} = discountPayload;
        const response = await axios.put(`/api/discounts/${_id}`, {...rest});
        if(response.status >= 300 || (response?.data?.length === 2 && response?.data[1] > 300)) {
          const fallbackErrorMessage = "Einige Felder fehlen. Bitte geben Sie den Namen, die Art und den Wert des Rabatts ein, um den Rabatt zu erstellen.";
          throw new Error(response?.data[0]?.error || fallbackErrorMessage);
        }
        else if (response.status === 200) {
          await this.handleSuccessfulResponse();
        }
      } catch (error) {
        this.alertError();
      }
    },
    async deleteDiscount() {
      try {
        const discount_id = this.discount._id;
        await axios.delete(`/api/discounts/${discount_id}`);
        this.isDeleteDiscountDialogOpen = false;
        this.$emit('refresh');
      } catch (error) {
        this.alertError();
      }
    },
    async assignDiscountToPharmacy(discountId, pharmacyId) {
      try{
        if (discountId && pharmacyId) {
          const response = await axios.get(`/api/discounts/${discountId}/assign_to/${pharmacyId}`);
          if (response.data.status >= 400) throw new Error()
          this.$emit('refresh');
          this.alertSuccess("Der Rabatt wurde erfolgreich mit der Apotheke verknüpft");
          return;
        }
        this.alertError('Bitte wählen Sie eine Apotheke aus, um fortzufahren')
      }
      catch (error) {
        console.log(error);
        this.alertError();
      }

    },
    ...mapMutations({
      alertError: "alert/error",
      alertSuccess: "alert/success",
    }),
  },
};
</script>
<style scoped lang="scss">

.action-btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px
}
.transparent-bg {
  background: transparent !important;
}

::v-deep .v-application--wrap {
    min-height: fit-content;
}
</style>