<template>
    <div class="vuetify-wrapper">
        <v-btn
            @click.stop="isDialogOpen = true"
            x-small
            outlined
            class="add-source"
        >
            <v-icon small color="black">mdi-plus</v-icon>
            Quelle hinzufügen
        </v-btn>

        <v-dialog
            content-class="vuetify-wrapper v-application"
            v-model="isDialogOpen"
            width="350"
        >
            <v-card>
                <v-card-title>
                    Quelle hinzufügen
                </v-card-title>
                <v-card-text>
                    <v-select
                        class="select-source"
                        v-model="sourceName"
                        :items="sourceNamesOptions"
                        placeholder="Quelle hinzufügen"
                        dense
                        outlined
                        clearable
                        hide-details
                    ></v-select>
                </v-card-text>
                <v-card-actions class="justify-end">
                    <v-btn @click="isDialogOpen = false" small>
                        <v-icon left>mdi-cancel</v-icon>
                        Abbrechen
                    </v-btn>
                    <v-btn
                        @click="submit"
                        class="submit ml-3"
                        color="primary"
                        small
                        :disabled="!sourceName?.trim().length"
                        > Speichern
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import axios from "axios";
import { mapMutations } from 'vuex';
import { predefinedSourceNames } from "../../constants";

export default {
    props: ['pharmacy_id'],
    data() {
        return {
            isDialogOpen: false,
            sourceName: '',
            sourceNamesOptions: predefinedSourceNames
        }
    },
    methods: {
        async submit() {
            try {
                const response = await axios.post(`/api/pharmacies/add_source_to_pharmacy/${this.pharmacy_id}/`, {
                    "name": this.sourceName,
                    "type": "admin",
                });
                if (response.status >= 400 || response.data[1] >= 400) throw new Error();

                this.isDialogOpen = false;
                window.location.reload()
            } catch {
                this.alertError()
            }
        },
        ...mapMutations({
            alertError: 'alert/error',
        })
    },
}
</script>
<style scoped>
    .add-source {
        margin-top: 6px;
    }
</style>