<template>
    <v-dialog
      v-model="isDialogOpen"
      content-class="v-application v-application--is-ltr"
      max-width="580px"
      :fullscreen="!$vuetify.breakpoint.mdAndUp"
    >
      <v-card id="discountCard">
        <v-card-title>{{ dialogTitle }}</v-card-title>
        <v-card-text>
          <div class="discount-form">
            <v-text-field
              id="discountName"
              @input="(value) => onChange('discountName', value)"
              :value.sync="value.discountName"
              outlined
              label="Rabatt Name"
            />
            <v-text-field
              id="description"
              @input="(value) => onChange('description', value)"
              :value.sync="value.description"
              outlined
              label="Rabatt Beschreibung"
            />
            <v-select
              class="discountType"
              @input="value => onChange('discountType', value)"
              :value.sync="value.discountType"
              outlined
              label="Rabatt Typ"
              :items="discountTypeOptions"
              variant="outlined"
            />
            <v-text-field
              id="discountValue"
              @input="(value) => onChange('discountValue', value)"
              :value.sync="value.discountValue"
              type="number"
              outlined
              label="Rabatt Wert"
              min="0"
            />
            <v-text-field
              id="startDate"
              @input="(value) => onChange('startDate', value)"
              :value.sync="value.startDate"
              type="date"
              outlined
              label="Rabatt Start date"
            />
            <v-text-field
              id="endDate"
              @input="(value) => onChange('endDate', value)"
              :value.sync="value.endDate"
              type="date"
              outlined
              label="Rabatt End date"
            />
            <v-select
              class="discountSource"
              @input="value => onChange('sources', value)"
              :value.sync="value.sources"
              outlined
              multiple
              label="Source"
              :items="discountSourceOptions"
              variant="outlined"
            />
            <v-switch
              id="isActive"
              label="Ist der Rabatt aktiv?"
              v-model="value.isActive"
            />
          </div>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            outlined
            color="secondary"
            @click="closeDialog"
          >
            Abbrechen
          </v-btn>
          <v-btn
            class="discount-btn"
            color="success"
            :disabled="disableDiscountCreation"
            id="createEditDiscount"
            density="comfortable"
            @click="submitForm"
          >
            {{ ctaTitle }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>

  <script>
  export default {
    props: {
      value: Object,
      dialogTitle: String,
      ctaTitle: String,
      isDiscountFormDialogOpen: Boolean
    },
    data: () => ({
      discountTypeOptions: [
        { text: "Prozent", value: "percentage" },
        { text: "Nummer", value: "number" },
      ],
      discountSourceOptions: [
        { text: "mep24", value: "mep24" },
        { text: "noweda_member", value: "noweda_member" },
        { text: "noweda_customer", value: "noweda_customer" },
        { text: "firstclass_hh_rp_sh_hb", value: "firstclass_hh_rp_sh_hb" }
      ],
      isActive: false,
    }),
    methods: {
      onChange(key, value) {
        this.$emit('input', {
          ...this.value, [key]: value
        })
      },
      submitForm() {
        this.$emit('submit', this.value);
      },
      closeDialog(){
        this.isDialogOpen = false;
      }
    },
    computed: {
      isDialogOpen: {
        get() {
          return this.isDiscountFormDialogOpen;
        },
        set(state) {
          this.$emit("update:isDiscountFormDialogOpen", state);
        },
      },
      disableDiscountCreation() {
        return !(
          this.value?.discountName?.trim()?.length
          && this.value?.description?.trim()?.length
          && this.value?.discountType
          && this.value?.discountValue
        )
      }
  },
  };
  </script>

  <style scoped></style>
