var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "vuetify-wrapper v-application" },
    [
      [
        _c("v-data-table", {
          staticClass: "v-application--is-ltr",
          attrs: {
            headers: _vm.headers,
            items: _vm.sources,
            "sort-by": "calories",
          },
          scopedSlots: _vm._u([
            {
              key: "top",
              fn: function () {
                return [
                  _c(
                    "v-toolbar",
                    { attrs: { flat: "" } },
                    [
                      _c("v-toolbar-title", [_vm._v("hinzufügen")]),
                      _vm._v(" "),
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-dialog",
                        {
                          attrs: { "max-width": "500px" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function ({ on, attrs }) {
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          staticClass: "mb-2",
                                          attrs: { color: "primary", dark: "" },
                                        },
                                        "v-btn",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { color: "black" } },
                                        [_vm._v("mdi-plus")]
                                      ),
                                      _vm._v(
                                        "\n                                Quelle\n                            "
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ]),
                          model: {
                            value: _vm.isSourceDialogOpen,
                            callback: function ($$v) {
                              _vm.isSourceDialogOpen = $$v
                            },
                            expression: "isSourceDialogOpen",
                          },
                        },
                        [
                          _vm._v(" "),
                          _c(
                            "v-card",
                            [
                              _c("v-card-title", [
                                _c("span", { staticClass: "text-h5" }, [
                                  _vm._v(_vm._s(_vm.formTitle)),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c(
                                "v-card-text",
                                [
                                  _c(
                                    "v-container",
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "v-application--is-ltr",
                                              attrs: { cols: "12" },
                                            },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  label: "hinzufügen name",
                                                },
                                                model: {
                                                  value: _vm.editedSourceName,
                                                  callback: function ($$v) {
                                                    _vm.editedSourceName = $$v
                                                  },
                                                  expression:
                                                    "editedSourceName",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-card-actions",
                                [
                                  _c("v-spacer"),
                                  _vm._v(" "),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        color: "blue darken-1",
                                        text: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.isSourceDialogOpen = false
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    Abbrechen\n                                "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { color: "primary", text: "" },
                                      on: { click: _vm.save },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    Speichern\n                                "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("DeleteAppointmentDialog", {
                        attrs: { message: "hinzufügen Löschen?" },
                        on: { "confirm-deletion": _vm.remove },
                        model: {
                          value: _vm.isDeleteDialogOpen,
                          callback: function ($$v) {
                            _vm.isDeleteDialogOpen = $$v
                          },
                          expression: "isDeleteDialogOpen",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
            {
              key: "item.actions",
              fn: function ({ item }) {
                return [
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn-edit",
                      attrs: { small: "" },
                      on: {
                        click: function ($event) {
                          return _vm.onEdit(item)
                        },
                      },
                    },
                    [
                      _c("v-icon", { attrs: { small: "" } }, [
                        _vm._v("mdi-pencil"),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn-delete",
                      attrs: { small: "", color: "error" },
                      on: {
                        click: function ($event) {
                          return _vm.onDelete(item)
                        },
                      },
                    },
                    [
                      _c("v-icon", { attrs: { small: "" } }, [
                        _vm._v("mdi-trash-can-outline"),
                      ]),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        }),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }