<template>
    <div class="vuetify-wrapper v-application">
        <template>
            <v-data-table
                :headers="headers"
                :items="sources"
                sort-by="calories"
                class="v-application--is-ltr"
            >
                <template v-slot:top>
                    <v-toolbar flat>
                        <v-toolbar-title>hinzufügen</v-toolbar-title>
                        <v-spacer></v-spacer>

                        <v-dialog
                            v-model="isSourceDialogOpen"
                            max-width="500px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    color="primary"
                                    dark
                                    class="mb-2"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    <v-icon color="black">mdi-plus</v-icon>
                                    Quelle
                                </v-btn>
                            </template>
                            <v-card>
                                <v-card-title>
                                    <span class="text-h5">{{ formTitle }}</span>
                                </v-card-title>
                                <v-card-text>
                                    <v-container>
                                        <v-row>
                                            <v-col cols="12" class="v-application--is-ltr">
                                                <v-text-field v-model="editedSourceName" label="hinzufügen name"></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>

                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        color="blue darken-1"
                                        text
                                        @click="isSourceDialogOpen = false"
                                    >
                                        Abbrechen
                                    </v-btn>
                                    <v-btn
                                        color="primary"
                                        text
                                        @click="save"
                                    >
                                        Speichern
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <DeleteAppointmentDialog
                            v-model="isDeleteDialogOpen"
                            message="hinzufügen Löschen?"
                            @confirm-deletion="remove"
                        />
                    </v-toolbar>
                </template>

                <template v-slot:item.actions="{ item }">

                    <v-btn
                        class="btn-edit"
                        small
                        @click="onEdit(item)"
                    >
                        <v-icon small>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn
                        class="btn-delete"
                        small
                        color="error"
                        @click="onDelete(item)"
                    >
                        <v-icon small>mdi-trash-can-outline</v-icon>
                    </v-btn>
                </template>
            </v-data-table>
        </template>
    </div>
</template>

<script>
import axios from 'axios';
import DeleteAppointmentDialog from '../appointments/DeleteAppointmentDialog.vue';

export default {
    components: {
        DeleteAppointmentDialog
    },
    data() {
        return {
            headers: [
                { text: 'Name', value: 'name' },
                { text: 'Time', value: 'timestamp' },
                { text: 'Actions', value: 'actions', sortable: false },
            ],
            sources: [],
            editedSourceName: '',
            editedSourceId: null,
            isEditing: false,
            isSourceDialogOpen: false,
            isDeleteDialogOpen: false,
        }
    },
    created() {
        this.getSources();
    },
    methods: {
        async getSources() {
            const response = await axios('/api/sources/');
            this.sources = response.data
        },
        async save() {
            if (!this.isEditing) {
                const response = await axios.post('/api/sources/', {
                        "name": this.editedSourceName,
                        "timestamp": new Date().toISOString().slice(0, -1),
                        "type": "admin",
                        "associated_discounts": [],
                    }
                );
            } else {
                const response = await axios.put(`/api/sources/${this.editedSourceId}`, {
                        "name": this.editedSourceName,
                        "timestamp": new Date().toISOString().slice(0, -1),
                        "type": "admin",
                        "associated_discounts": [],
                    }
                );
            }
            this.getSources();
            this.isSourceDialogOpen = false
        },
        reset() {
            this.isEditing = false;
            this.editedSourceName = '';
            this.editedSourceId = null;
        },
        onEdit(item) {
            this.isEditing = true;
            this.editedSourceName = item.name;
            this.editedSourceId = item._id;
            this.isSourceDialogOpen = true;
        },
        onDelete(item) {
            this.editedSourceName = item.name;
            this.editedSourceId = item._id;
            this.isDeleteDialogOpen = true
        },

        async remove() {
            const response = await axios.delete(`/api/sources/${this.editedSourceId}`);
            this.getSources();
            this.isDeleteDialogOpen = false
        },
    },
    computed: {
        formTitle () {
            return this.isEditing === false ? 'Neue hinzufügen' : 'bearbeiten hinzufügen'
        },
    },
    watch: {
        isSourceDialogOpen(isOpen) {
            if (!isOpen) this.reset()
        },
        isDeleteDialogOpen(isOpen) {
            if (!isOpen) this.reset()
        },
    },
}
</script>

<style></style>