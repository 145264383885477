<template>
  <div class="vuetify-wrapper v-application">
    <div class="discount-form-container elevation-5">
      <h2 style="padding-bottom: 0.5rem">Rabatt</h2>
      <v-btn elevated type="button" id="newDiscountBtn" @click="isCreateDialogOpen = true">
        <v-icon>mdi-plus</v-icon>
        Neuer Rabatt
      </v-btn>
      <discount-form-dialog
        v-model="discount"
        dialogTitle="Erstellen Rabatt"
        ctaTitle="Rabatt erstellen"
        :ctaHandler="createDiscount"
        :isDiscountFormDialogOpen.sync="isCreateDialogOpen"
        @submit="createDiscount"
      />
    </div>
    <div class="discount-form-list elevation-5">
      <div class="no-discounts" v-if="discounts?.length === 0">
        Es können keine Rabatte angezeigt werden
      </div>
      <v-simple-table v-else class="discount-table">
        <template v-slot:default>
          <thead>
            <tr class="vuetify-wrapper">
              <th>Rabatt Name</th>
              <th>Rabatt Beschreibung</th>
              <th>Rabatt Typ</th>
              <th>Rabatt Wert</th>
              <th>Rabatt Startdatum</th>
              <th>Rabatt Enddatum</th>
              <th>Rabatt ist aktiv?</th>
              <th class="text-center">Aktionen</th>
            </tr>
          </thead>
          <tbody>
            <discounts-table-row
              v-for="(item) in discounts"
              :discountDetails="item"
              @refresh="fetchAllDiscounts"
            />
          </tbody>
        </template>
      </v-simple-table>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapMutations } from "vuex";
import DiscountsTableRow from "./discounts/DiscountsTableRow.vue";
import DiscountFormDialog from "./discounts/DiscountFormDialog.vue";
export default {
  name: "PricingDiscounts",
  components: {
    "discounts-table-row": DiscountsTableRow,
    "discount-form-dialog": DiscountFormDialog
  },
  data: () => ({
    discount: {
      discountName: "",
      discountType: "",
      discountValue: null,
      description: "",
      startDate: "",
      endDate: "",
      sources: [],
      isActive: false,
    },
    discountTypeOptions: [
      { text: "Prozent", value: "percentage" },
      { text: "Nummer", value: "number" },
    ],
    discounts: [],
    isCreateDialogOpen: false
  }),
  methods: {
    async fetchAllDiscounts() {
      try {
        const response = await axios.get("/api/discounts/");
        this.discounts = response.data || [];
      } catch (error) {
        this.alertError();
      }
    },
    async handleSuccessfulResponse() {
      this.resetForm();
      await this.fetchAllDiscounts();
      this.alertSuccess(`Der Rabatt wurde erfolgreich erstellt`);
    },
    constructPayload(){
      return {
        discountName: this.discount.discountName,
        description: this.discount.description,
        discountType: this.discount.discountType,
        discountValue: this.discount.discountValue,
        isActive: this.discount.isActive,
        sources: this.discount.sources,
        ...(this.discount.startDate && { startDate: this.discount.startDate }),
        ...(this.discount.endDate && { endDate: this.discount.endDate }),
      };
    },
    async createDiscount(){
      try {
        const discountPayload = this.constructPayload();
        const response = await axios.post("/api/discounts/", discountPayload);
        if(response.status >= 300 || (response?.data?.length === 2 && response?.data[1] > 300)) {
          const fallbackErrorMessage = "Einige Felder fehlen. Bitte geben Sie den Namen, die Art und den Wert des Rabatts ein, um den Rabatt zu erstellen.";
          throw new Error(response?.data[0]?.error || fallbackErrorMessage);
        }
        else if (response.status === 200) {
          await this.handleSuccessfulResponse();
          this.isCreateDialogOpen = false;
        }
      } catch (error) {
        console.log("Error: ",error);
        this.alertError();
      }
    },
    resetForm(){
      this.discount = {
        discountName: "",
        discountType: "",
        discountValue: null,
        description: "",
        startDate: "",
        endDate: "",
        sources: {},
        isActive: false,
      }
    },
    ...mapMutations({
      alertError: "alert/error",
      alertSuccess: "alert/success",
    }),
  },
  created() {
    this.fetchAllDiscounts();
  },
};
</script>

<style scoped>
.discount-form-container {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.discount-form {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
}
.discount-form-list {
  margin-top: 1rem;
}
.discount-table {
  border: 1px solid #EEE;
}
.text-center {
  text-align: center;
}
.no-discounts {
  text-align: center;
  width: 100%;
  padding: 1rem;
  background: #FFFFFF;
  border: 1px solid #F0F0F0;
}
</style>
